<template>
  <div class="bg-white">
    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="relative z-40 lg:hidden" @close="mobileMenuOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-zinc-900 pb-12 shadow-xl">
              <div class="flex px-4 pb-2 pt-5">
                <button type="button" class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400" @click="mobileMenuOpen = false">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <!-- Links -->
              <TabGroup as="div" class="mt-2">
                <div class="border-b border-gray-200">
                  <TabList class="-mb-px flex space-x-8 px-4">
                    <Tab as="template" v-for="category in navigation.categories" :key="category.name" v-slot="{ selected }">
                      <button :class="[selected ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-900', 'flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium']">{{ category.name }}</button>
                    </Tab>
                  </TabList>
                </div>
                <TabPanels as="template">
                  <TabPanel v-for="category in navigation.categories" :key="category.name" class="space-y-12 px-4 py-6">
                    <div class="grid grid-cols-2 gap-x-4 gap-y-10">
                      <div v-for="item in category.featured" :key="item.name" class="group relative">
                        <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                          <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" />
                        </div>
                        <a :href="item.href" class="mt-6 block text-sm font-medium text-white">
                          <span class="absolute inset-0 z-10" aria-hidden="true" />
                          {{ item.name }}
                        </a>
                        <p aria-hidden="true" class="mt-1 text-sm text-gray-500">Shop now</p>
                      </div>
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>

              <div class="space-y-6 border-t border-gray-200 px-4 py-6">
                <div v-for="page in navigation.pages" :key="page.name" class="flow-root">
                  <a :href="page.href" class="-m-2 block p-2 font-medium text-white">{{ page.name }}</a>
                </div>
              </div>

              <div class="space-y-6  px-4 py-6">

              </div>

              <div class="space-y-6  px-4 py-6">
                <!-- Currency selector -->

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Hero section -->
    <div class="relative bg-gray-900">
      <!-- Decorative image and overlay -->
      <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
        <img src="@/assets/hero.jpg" alt="" class="h-full w-full object-cover object-center" />
      </div>
      <div aria-hidden="true" class="absolute inset-0 bg-gray-900 opacity-50" />

      <!-- Navigation -->
      <header class="relative z-10">
        <nav aria-label="Top">

          <!-- Secondary navigation -->
          <div class="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div>
                <div class="flex h-16 items-center justify-between">
                  <!-- Logo (lg+) -->
                  <div class="hidden lg:flex lg:flex-1 lg:items-center">
                    <a href="#">
                      <span class="sr-only">Game On Cosham</span>
                      <img class="h-8 w-auto" src="@/assets/logotrans.png" alt="Game on cosham" />
                    </a>
                  </div>

                  <div class="hidden h-full lg:flex">
                    <!-- Flyout menus -->
                    <PopoverGroup class="inset-x-0 bottom-0 px-4">
                      <div class="flex h-full justify-center space-x-8">
                        <Popover v-for="category in navigation.categories" :key="category.name" class="flex" v-slot="{ open }">
                          <div class="relative flex">
                            <PopoverButton class="relative z-10 flex items-center justify-center text-sm font-medium text-white transition-colors duration-200 ease-out">
                              {{ category.name }}
                              <span :class="[open ? 'bg-white' : '', 'absolute inset-x-0 -bottom-px h-0.5 transition duration-200 ease-out']" aria-hidden="true" />
                            </PopoverButton>
                          </div>

                          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <PopoverPanel class="absolute inset-x-0 top-full text-sm text-gray-500">
                              <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                              <div class="absolute inset-0 top-1/2 bg-white shadow" aria-hidden="true" />

                              <div class="relative bg-white">
                                <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                  <div class="grid grid-cols-4 gap-x-8 gap-y-10 py-16">
                                    <div v-for="item in category.featured" :key="item.name" class="group relative">
                                      <div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                        <img :src="item.imageSrc" :alt="item.imageAlt" class="object-cover object-center" />
                                      </div>
                                      <a :href="item.href" class="mt-4 block font-medium text-gray-900">
                                        <span class="absolute inset-0 z-10" aria-hidden="true" />
                                        {{ item.name }}
                                      </a>
                                      <p aria-hidden="true" class="mt-1">Shop now</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </PopoverPanel>
                          </transition>
                        </Popover>

                        <a v-for="page in navigation.pages" :key="page.name" :href="page.href" class="flex items-center text-sm font-medium text-white">{{ page.name }}</a>
                      </div>
                    </PopoverGroup>
                  </div>

                  <!-- Mobile menu and search (lg-) -->
                  <div class="flex flex-1 items-center lg:hidden">
                    <button type="button" class="-ml-2 p-2 text-white" @click="mobileMenuOpen = true">
                      <span class="sr-only">Open menu</span>
                      <Bars3Icon class="h-6 w-6" aria-hidden="true" />
                    </button>

                    <!-- Search -->
                    <a href="#" class="ml-2 p-2 text-white">

                    </a>
                  </div>

                  <!-- Logo (lg-) -->
                  <a href="#" class="lg:hidden">
                    <span class="sr-only">Your Company</span>
                    <img src="@/assets/logotrans.png" alt="" class="h-8 w-auto" />
                  </a>

                  <div class="flex flex-1 items-center justify-end">

                    <div class="flex items-center lg:ml-8">
                      <!-- Help -->


                      <!-- Cart -->
                      <div class="flex ml-4 flow-root lg:ml-8 text-grey-300">
                        <div class="group -m-2 flex items-center p-2">
                          <phone-icon class="h-4 w-4 flex-shrink-0 text-white" aria-hidden="true"></phone-icon>
                          <span class="text-gray-300"> &nbsp;023 9238 3313</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div class="relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-32 lg:px-0">
        <img src="@/assets/logotrans.png" alt="">
        <p class="mt-4 text-xl text-white">
          WeBuy WeSell WeTrade <br>
          Games, Consoles, Accessories, Mobile Phones, Tablets, Laptops
        </p>
        <a href="https://www.ebay.co.uk/str/gameoncoham" target="_blank" class="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100">Shop Our Ebay Store</a>
      </div>
    </div>

    <main>
      <!-- Featured section -->
      <section aria-labelledby="social-impact-heading" class="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8">
        <div class="relative overflow-hidden rounded-lg">
          <div class="absolute inset-0">
            <img src="@/assets/storefront.jpg" alt="" class="h-full w-full object-cover object-center" />
          </div>
          <div class="relative bg-gray-900 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
            <div class="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 id="social-impact-heading" class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span class="block sm:inline">Visit Us Today </span>
                <span class="block sm:inline">and Let the Games Begin!</span>
              </h2>
              <p class="mt-3 text-xl text-white">
                Game On Cosham is not just a store – it's a portal to boundless excitement,
                endless exploration, and unforgettable memories. Step into our realm of gaming
                wonder and let the adventure unfold. Our friendly experts are here to guide you
                on your gaming journey, helping you discover the latest releases, rare gems,
                and thrilling experiences that await.
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Map Section -->
      <section id="location" aria-labelledby="category-heading" class="pt-8 sm:pt-12 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div class="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
          <h2 id="category-heading" class="text-2xl font-bold tracking-tight text-gray-900">Our Location</h2>
        </div>
      </section>
      <section aria-labelledby="category-heading" class="grid grid-cols-1 md:grid md:grid-cols-2 pt-24 sm:pt-8 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div class="map-responsive ml-0 sm:ml-10">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2519.1938164731014!2d-1.0664841!3d50.8460951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487442d6081f1279%3A0xcf3c179d6250858f!2sGame%20On!5e0!3m2!1sen!2suk!4v1691331757326!5m2!1sen!2suk" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="mx-auto">
          <p class="text-xl font-bold">Address</p>
          <p>
            Unit 1-2, Crown Buildings
            High St
            Portsmouth
            PO6 3BU
            UK
          </p>

          <p class="text-xl font-bold pt-8 pb-2">Opening Hours</p>
          <ul class="justify-self-start">
            <li>Mon:	9:00am - 5:30pm</li>
            <li>Tue:	9:00am - 5:30pm</li>
            <li>Wed:	9:00am - 5:30pm</li>
            <li>Thu:	9:00am - 5:30pm</li>
            <li>Fri:	9:00am - 5:30pm</li>
            <li>Sat:	9:00am - 5:30pm</li>
            <li>Sun:	10:00am - 4:00pm</li>
          </ul>

        </div>
      </section>

      <!-- gallery section -->
      <section id="gallery" aria-labelledby="category-heading" class="pt-8 sm:pt-12 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div class="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0">
          <h2 id="category-heading" class="text-2xl font-bold tracking-tight text-gray-900">Our Gallery</h2>
        </div>
      </section>
      <section aria-labelledby="social-impact-heading" class="mx-auto max-w-7xl  pt-8 pb-8 sm:px-6">
        <div class="bg-white">
          <div class="mx-auto max-w-2xl px-4  sm:px-6 lg:max-w-7xl lg:px-8">
            <div class="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/1.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/2.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/3.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/4.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/5.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
              <div  class="group relative">
                <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none lg:h-80">
                  <img src="../assets/gallery/6.jpg" class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section aria-labelledby="social-impact-heading" class="mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8">
        <div class="relative overflow-hidden rounded-lg">
          <div class="absolute inset-0">
            <img src="@/assets/storefront.jpg" alt="" class="h-full w-full object-cover object-center" />
          </div>
          <div class="relative bg-gray-900 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16">
            <div class="relative mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 id="social-impact-heading" class="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span class="block sm:inline">Buy, Sell, Trade  </span>
                <span class="block sm:inline">– Power Up Your Collection!</span>
              </h2>
              <p class="mt-3 text-xl text-white">
                Cash in on your gaming victories! Turn your pre-loved consoles and games into
                a virtual goldmine. Our expert team offers top-dollar trade-in values and fair
                deals that will leave you cheering. Upgrade to the newest console,
                snag limited-edition releases,
                or indulge in a gaming marathon with friends – the choice is yours!
              </p>
            </div>
          </div>
        </div>
      </section>

      <!-- Testimonials -->
      <div id="testimonials" class="flex">
        <section class="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <figure class="mx-auto max-w-2xl">
            <p class="sr-only">5 out of 5 stars</p>
            <div class="flex gap-x-1 text-indigo-600">
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
            </div>
            <blockquote class="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
              <p>“They take in any kind of geek memboria not just games. Staff are very friendly and offer way better prices
                than cex. I take the bus down to here as it is worth. Got 65 quid for only a handful of games. Trade your
                stuff in here. Good for a bit of extra cash.”</p>
            </blockquote>
            <figcaption class="mt-10 flex items-center gap-x-6">
              <img class="h-12 w-12 rounded-full bg-gray-50" src="@/assets/icon.jpeg" alt="" />              <div class="text-sm leading-6">
                <div class="font-semibold text-gray-900">Emma G</div>
                <div class="mt-0.5 text-gray-600">2 months ago</div>
              </div>
            </figcaption>
          </figure>
        </section>
        <section class="bg-white px-6 py-24 sm:py-32 lg:px-8">
          <figure class="mx-auto max-w-2xl">
            <p class="sr-only">5 out of 5 stars</p>
            <div class="flex gap-x-1 text-indigo-600">
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
              <StarIcon class="h-5 w-5 flex-none" aria-hidden="true" />
            </div>
            <blockquote class="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
              <p>“Really enjoyed visiting this shop again after nearly 7 years. Still has great customer service, amazing
                product range and super prices. Well done fellas. Will be back again soon and already recommending that
                friends make the trip over to check the shop out.”</p>
            </blockquote>
            <figcaption class="mt-10 flex items-center gap-x-6">
              <img class="h-12 w-12 rounded-full bg-gray-50" src="@/assets/icon.jpeg" alt="" />
              <div class="text-sm leading-6">
                <div class="font-semibold text-gray-900">Jon P</div>
                <div class="mt-0.5 text-gray-600">a year Ago</div>
              </div>
            </figcaption>
          </figure>
        </section>
      </div>

    </main>

    <footer aria-labelledby="footer-heading" class="bg-gray-900">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="py-20 xl:grid xl:grid-cols-3 xl:gap-8">
          <div class="grid grid-cols-2 gap-8 xl:col-span-2">
            <div class="space-y-12 md:grid md:grid-cols-2 md:gap-8 md:space-y-0">
              <div>
                <h3 class="text-sm font-medium text-white">Connect</h3>
                <ul role="list" class="mt-6 space-y-6">
                  <li v-for="item in footerNavigation.connect" :key="item.name" class="text-sm">
                    <a :href="item.href" class="text-gray-300 hover:text-white">{{ item.name }}</a>
                  </li>
                </ul>
              </div>
              <div>
                <h3 class="text-sm font-medium text-white">Opening Hours</h3>
                <ul class="justify-self-start text-gray-300 pt-4">
                  <li>Mon:	9:00am - 5:30pm</li>
                  <li>Tue:	9:00am - 5:30pm</li>
                  <li>Wed:	9:00am - 5:30pm</li>
                  <li>Thu:	9:00am - 5:30pm</li>
                  <li>Fri:	9:00am - 5:30pm</li>
                  <li>Sat:	9:00am - 5:30pm</li>
                  <li>Sun:	10:00am - 4:00pm</li>
                </ul>
              </div>
            </div>
            <div class="space-y-12 md:grid md:grid-cols-1 md:gap-8 md:space-y-0">
              <img src="@/assets/logotrans.png" class="w-full mx-auto"/>            </div>
          </div>
          <div class="mt-12 md:mt-16 xl:mt-0">
            <a href="https://www.ebay.co.uk/str/gameoncoham" target="_blank" class="mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100">Shop Our Ebay Store</a>
          </div>
        </div>

        <div class="border-t border-gray-800 py-10">
          <p class="text-sm text-gray-400">Copyright &copy; {{d.getFullYear()}} Game On Cosham.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { StarIcon, PhoneIcon } from '@heroicons/vue/20/solid'
const navigation = {
  pages: [

    { name: 'Location', href: '#location' },
    { name: 'Testimonials', href: '#testimonials'},
    { name: 'Gallery', href: '#gallery'},
  ],
}

const footerNavigation = {
  connect: [
    { name: 'Contact Us', href: '#location' },
    { name: 'Facebook', href: 'https://www.facebook.com/gameoncosham' },
    { name: 'Instagram', href: 'https://www.instagram.com/gameoncosham/?hl=en' },
  ],
}
const d = new Date();
const mobileMenuOpen = ref(false)
</script>
<style>
.map-responsive{

  overflow:hidden;

  padding-bottom:56.25%;

  position:relative;

  height:0;

}

.map-responsive iframe{

  left:0;

  top:0;

  height:100%;

  width:100%;

  position:absolute;

}
</style>